import { io, Socket } from 'socket.io-client'
import { defineStore } from 'pinia'
import { formatLinks } from '~core/plugins/format'
import { useAuth } from '~/stores/auth'
import type { ChatMessage } from '~/models/chat'

interface ChatState {
  socket: Socket | null
}

export const useChat = defineStore('chat', () => {
  const authStore = useAuth()
  const state = reactive<ChatState>({
    socket: null,
  })

  const wsChatUrl = useRuntimeConfig().public.wsChatUrl

  async function initChat() {
    console.log('chat: init')
    initSocket()
    await connect()
  }

  async function resetChat() {
    console.log('chat: reset')
    await disconnect()
    state.socket = null
  }

  function initSocket() {
    if (state.socket) {
      return
    }
    if (!authStore.auth?.access_token) {
      throw new Error('Chat: no access token on init connection')
    }

    state.socket = io(wsChatUrl, {
      autoConnect: false,
      transports: ['websocket'],
      auth: {
        token: authStore.auth.access_token,
      },
    })

    state.socket.on('recieve', (data: ChatMessage) => {
      console.log('chat: receive', data)
    })
  }

  let connectChatPromise: null | Promise<boolean> = null
  async function connect() {
    if (connectChatPromise) {
      console.log('chat: already connecting, return promise')
      return connectChatPromise
    }
    if (state.socket?.disconnected) {
      console.log('chat: connecting start')
      state.socket.connect()

      connectChatPromise = new Promise((resolve, reject) => {
        state.socket?.once('connect', () => {
          resolve(true)
        })
        state.socket?.once('connect_error', (error: any) => reject(error))
      })

      await connectChatPromise
      connectChatPromise = null
      console.log('chat: service connected')
    } else {
      console.log('chat: already connected')
    }
  }

  async function disconnect() {
    console.log('chat: try to disconnect')
    if (state.socket?.connected) {
      let gotEvent = false
      const reason = await new Promise((resolve, reject) => {
        state.socket?.once('disconnect', (reason) => {
          console.log('chat: got disconnect event')
          gotEvent = true
          resolve(reason)
        })
        console.log('chat: start disconnect')
        state.socket?.connected && state.socket.disconnect()
        setTimeout(() => {
          if (!gotEvent) {
            reject(
              new Error(
                'chat: did not get disconnect event in time on disconnect'
              )
            )
          }
        }, 1000)
      })

      console.log('chat: service disconnected, reason:', reason)
    }
  }

  return {
    socket: toRef(state, 'socket'),
    getMessageHtml(text: string) {
      if (!text) {
        return ''
      }

      return formatLinks(text)
        .trim()
        .replace(/[\r\n]/g, '\n')
        .replace(/\n/g, '<br />')
    },
    initChat,
    resetChat,
  }
})
